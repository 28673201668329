import React from 'react'
import { Link } from 'react-router-dom';
import "../header/header.css";
import logo from '../assets/logo.png';
export const Header = () => {
  return (
    <header className="header">
    <nav className="navbar">
      <div className="logo">biru</div>
      <ul className="nav-links">
        <li><Link to="/about">About</Link></li>
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/contact">Contact us</Link></li>
        <li><Link to="/pages">Pages</Link></li>
        <li><button className="get-started">Get Started</button></li>
      </ul>
    </nav>
    <div className="hero-section">
      <div className="hero-text">
        <h1>We solve business problems with technology.</h1>
        <p>Our performance is your success. Our passion is innovation. Our expertise is unmatched. We get you more.</p>
        <button className="btn-primary">Get Started</button>
        <button className="btn-secondary">View Case Study</button>
      </div>
      <img src={logo} alt="Hero" className="hero-image" />
    </div>
  </header>
  )
}
