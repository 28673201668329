import React from 'react'
import { Link } from 'react-router-dom';
import "./footer.css"
export const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2024 Kiddo Closet. All rights reserved.</p>
      <p>
        <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link> | <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
      </p>
    </footer>
  );
}

