import React from 'react';
import "../services/sevices.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faCloud, faShieldAlt, faBriefcase, faHeadset, faPlug } from '@fortawesome/free-solid-svg-icons';

const iconMap = {
  'fa-cogs': faCogs,
  'fa-cloud': faCloud,
  'fa-shield-alt': faShieldAlt,
  'fa-briefcase': faBriefcase,
  'fa-headset': faHeadset,
  'fa-plug': faPlug
};
const ServiceCards = () => {
  const services = [
    {
      title: 'Software Services',
      description: 'Quisque neque nisi, euismod eu dui eget, egestas pellentesque mauris.',
      icon: 'fa-cogs' // Font Awesome icon name
    },
    {
      title: 'Cloud Services',
      description: 'Quisque neque nisi, euismod eu dui eget, egestas pellentesque mauris.',
      icon: 'fa-cloud' // Font Awesome icon name
    },
    {
      title: 'Security Services',
      description: 'Quisque neque nisi, euismod eu dui eget, egestas pellentesque mauris.',
      icon: 'fa-shield-alt' // Font Awesome icon name
    },
    {
      title: 'Consulting Services',
      description: 'Praesent blandit vel eros eu efficitur. Nullam sit amet commodo libero.',
      icon: 'fa-briefcase' // Font Awesome icon name
    },
    {
      title: 'Support Services',
      description: 'Praesent blandit vel eros eu efficitur. Nullam sit amet commodo libero.',
      icon: 'fa-headset' // Font Awesome icon name
    },
    {
      title: 'Integration Services',
      description: 'Praesent blandit vel eros eu efficitur. Nullam sit amet commodo libero.',
      icon: 'fa-plug' // Font Awesome icon name
    }
  ];
  
    return (
      <div className="service-cards-container">
        <div className="service-cards">
          {services.map((service, index) => (
            <div className="card" key={index}>
              <FontAwesomeIcon icon={iconMap[service.icon]} size="3x" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <button className="learn-more">Learn more</button>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ServiceCards;


